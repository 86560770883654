import { memo } from 'react';
import styled from '@emotion/styled';

import { Film } from '@app/api/resources/Film';
import { PlaybackLanguages } from '@app/api/resources/PlaybackLanguages';

import { color as themeColors } from '@app/themes/mubi-theme';

import FadeIn from '@app/components/FadeIn';
import FilmPlaybackLanguagesMeta from '@app/components/FilmPlaybackLanguagesMeta';

type FilmTileMetaProps = {
  film: Film;
  playbackLanguages: PlaybackLanguages;
  darkTheme?: boolean;
};

const FilmTileMeta = ({
  film,
  playbackLanguages,
  darkTheme,
}: FilmTileMetaProps) => {
  if (!playbackLanguages) {
    return <FilmMetaSkeleton />;
  }
  return (
    <FadeIn isShowing fadeInSeconds={0.3}>
      <FilmMetaContainer>
        <FilmPlaybackLanguagesMeta
          film={film}
          isWatchable={true}
          playbackLanguages={playbackLanguages}
          iconColor={darkTheme ? themeColors.white : themeColors.black}
          showContentRating={false}
        />
      </FilmMetaContainer>
    </FadeIn>
  );
};

export default memo(FilmTileMeta);

const FilmMetaContainer = styled.div`
  pointer-events: none;
  font-size: 14px;
  letter-spacing: -0.1px;
  & li:not(:last-child) {
    margin-right: 8px;
  }
  & ul {
    flex-wrap: nowrap;
    white-space: nowrap;
  }
  & li {
    margin-bottom: 0px;
  }
  & svg {
    width: auto;
    height: 12px;
  }
  & li:last-child svg {
    height: 13.5px;
  }
`;

const FilmMetaSkeleton = styled.div`
  height: 21px;
`;
