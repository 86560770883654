import { useEffect, useState } from 'react';

import { FilmId } from '@app/api/resources/Film';

import usePopulateUserRatingForFilms from '@app/hooks/populate-store/usePopulateUserRatingForFilms';
import usePopulateUserWatchlistForFilms from '@app/hooks/populate-store/usePopulateUserWatchlistForFilms';

type FilmActionsButtonsProps = {
  filmIds: FilmId[];
  filmActionsToInit: { rating: boolean; watchlist: boolean };
};

const useInitialiseFilmActionButtons = ({
  filmIds = null,
  filmActionsToInit = {
    rating: true,
    watchlist: true,
  },
}: FilmActionsButtonsProps) => {
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const [isLoadingRatings] = usePopulateUserRatingForFilms(filmIds);
  const [, isLoadingWatchlist] = usePopulateUserWatchlistForFilms(filmIds);

  const isRatingPopulated = !isLoadingRatings;
  const isWatchlistPopulated = !isLoadingWatchlist;
  useEffect(() => {
    if (filmActionsToInit.rating && filmActionsToInit.watchlist) {
      setTimeout(
        () => setAllDataLoaded(isRatingPopulated && isWatchlistPopulated),
        100,
      );
    } else if (filmActionsToInit.rating) {
      setTimeout(() => setAllDataLoaded(isRatingPopulated), 100);
    } else if (filmActionsToInit.watchlist) {
      setTimeout(() => setAllDataLoaded(isWatchlistPopulated), 100);
    }
  }, [isRatingPopulated, isWatchlistPopulated]);

  return [allDataLoaded];
};
export default useInitialiseFilmActionButtons;
