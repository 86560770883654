import { memo, useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import dynamic from 'next/dynamic';
import { v4 as uuidv4 } from 'uuid';
import styled from '@emotion/styled';

import { Film } from '@app/api/resources/Film';

import { isMobileWidth } from '@app/services/breakpoints';

import useInitialiseFilmActionButtons from '@app/hooks/populate-store/useInitialiseFilmActionButtons';
import useAppSelector from '@app/hooks/utils/useAppSelector';
import useIsMounted from '@app/hooks/utils/useIsMounted';

import { colorThemesTypes } from '@app/components/icons/FilmActionIcons';

const FilmTileWatchListButton = dynamic(
  () =>
    import(
      /* webpackChunkName: "FilmTileWatchListButton" */
      '@app/components/film-tile/shared-film-tile-parts/FilmTileWatchListButton'
    ),
  { ssr: false },
);

const RatingButton = dynamic(
  () =>
    import(
      /* webpackChunkName: "RatingButton" */
      '@app/components/buttons/RatingButton'
    ),
  { ssr: false },
);

const FilmTileActionButtonsRequireUserLogin = dynamic(
  () =>
    import(
      /* webpackChunkName: "FilmTileActionButtonsRequireUserLogin" */
      '@app/components/film-tile/shared-film-tile-parts/FilmTileActionButtonsRequireUserLogin'
    ),
);

type FilmTileActionsProps = {
  film: Film;
  isVisible: boolean;
  showWatchListButton: boolean;
  showRatingButton: boolean;
  snowplowElement: string;
  overflowElementId?: string;
  transition?: boolean;
  theme?: colorThemesTypes;
  onWatchlistClick?: () => void;
};

const FilmTileActions = ({
  isVisible,
  film,
  showWatchListButton,
  showRatingButton,
  snowplowElement,
  transition = true,
  overflowElementId = null,
  theme = 'light',
  onWatchlistClick,
}: FilmTileActionsProps) => {
  const [filmId, setFilmId] = useState<number>(null);
  const [uniqueId] = useState(uuidv4());

  const { isAuthenticated, isMobile } = useAppSelector(
    state => ({
      isAuthenticated: state.user.isAuthenticated,
      isMobile: state.appState.isMobile,
    }),
    shallowEqual,
  );

  const isMounted = useIsMounted();

  const [contentLoaded] = useInitialiseFilmActionButtons({
    filmIds: [filmId],
    filmActionsToInit: {
      rating: showRatingButton,
      watchlist: showWatchListButton,
    },
  });

  useEffect(() => {
    if (isVisible && !contentLoaded) {
      setFilmId(film?.id);
    }
  }, [isVisible, contentLoaded, film?.id]);

  if (!isMounted || isMobile || isMobileWidth()) {
    return null;
  }

  if (!isAuthenticated && isVisible) {
    return (
      <FilmTileActionButtonsRequireUserLogin
        theme={theme}
        showWatchListButton={showWatchListButton}
        showRatingButton={showRatingButton}
      />
    );
  }

  return (
    <FilmActionsContainer
      isVisible={contentLoaded && isVisible}
      transition={transition}
    >
      {showWatchListButton ? (
        <FilmTileWatchListButton
          film={film}
          snowplowElement={snowplowElement}
          uniqueId={uniqueId}
          overflowElementId={overflowElementId}
          theme={theme}
          onWatchlistClick={onWatchlistClick}
        />
      ) : null}
      {showRatingButton ? (
        <RatingButton
          film={film}
          snowplowElement={snowplowElement}
          buttonWidth="28px"
          theme={theme}
        />
      ) : null}
    </FilmActionsContainer>
  );
};

export default memo(FilmTileActions);

const FilmActionsContainer = styled.div<{
  isVisible: boolean;
  transition: boolean;
}>`
  padding: 1px 0;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  transition: opacity 0.1s ${props => props.theme.animations.filmTileDefault}
    0.1s;
  @media ${props => props.theme.helpers.supportsHoverMq} {
    opacity: ${props => (props.isVisible === false ? 0 : 1)};
  }
`;
