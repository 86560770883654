import { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { v4 as uuidv4 } from 'uuid';
import styled from '@emotion/styled';

import {
  checkIsValidContentRatingLabel,
  Film,
  getGenres,
} from '@app/api/resources/Film';
import { PlaybackLanguages } from '@app/api/resources/PlaybackLanguages';

import { commaSeparateStringArray } from '@app/services/utils';

import { color as themeColors } from '@app/themes/mubi-theme';

import ContentRating from '@app/components/ContentRating';
import {
  FilmMeta4KIcon,
  FilmMetaAudioOptionsIcon,
  FilmMetaDolbyAtmosAudioIcon,
  FilmMetaDurationIcon,
  FilmMetaFiveOneAudioIcon,
  FilmMetaHDIcon,
  FilmMetaSubtitleOptionsIcon,
} from '@app/components/icons/FilmMetaIcons';
import Tooltip from '@app/components/tool-tip/Tooltip';

const getAudioOptions = (audioOptions: PlaybackLanguages['audio_options']) => {
  if (Array.isArray(audioOptions)) {
    return commaSeparateStringArray(audioOptions);
  }

  return '';
};

const getDuration = (playbackLanguages: PlaybackLanguages, film: Film) => {
  const durationInSeconds = playbackLanguages?.media_options?.duration;
  return durationInSeconds ? Math.round(durationInSeconds / 60) : film.duration;
};

const getMoreSubtitlesMessage = (
  subtitleOptions: PlaybackLanguages['subtitle_options'],
) => {
  if (Array.isArray(subtitleOptions) && subtitleOptions.length > 2) {
    return commaSeparateStringArray(subtitleOptions);
  }
  return '';
};

type FilmPlaybackLanguagesMetaProps = {
  film: Film;
  playbackLanguages: PlaybackLanguages;
  isWatchable: boolean;
  showGenres?: boolean;
  showContentRating?: boolean;
  iconColor?: string;
  overflowElementId?: string;
};

const FilmPlaybackLanguagesMeta = ({
  film,
  playbackLanguages,
  isWatchable,
  showGenres = false,
  showContentRating = true,
  iconColor = themeColors.white,
  overflowElementId = 'FilmDetailsAboutFilm',
}: FilmPlaybackLanguagesMetaProps) => {
  const { t } = useTranslation('common');
  const [uniqueId] = useState(uuidv4());
  const moreSubtitlesMessage = getMoreSubtitlesMessage(
    playbackLanguages?.subtitle_options,
  );

  const getSubtitleOptions = () => {
    if (playbackLanguages?.subtitle_options) {
      if (moreSubtitlesMessage.length > 1) {
        return (
          <Tooltip
            uniqId={`${uniqueId}-subtitle-options`}
            message={moreSubtitlesMessage}
            tooltipOptions={{
              overflowElementId: overflowElementId,
              fontSize: '16px',
              padding: '8px',
            }}
          >
            <MoreSubtitlesShort>
              <Opacity>{`${playbackLanguages.subtitle_options[0]} & ${t(
                'common.list_languages.more',
                {
                  count: playbackLanguages.subtitle_options.length - 1,
                },
              )}`}</Opacity>
            </MoreSubtitlesShort>
          </Tooltip>
        );
      }
      return (
        <Opacity>
          {commaSeparateStringArray(playbackLanguages.subtitle_options)}
        </Opacity>
      );
    }
    return null;
  };

  const genres = getGenres(film);
  const audioOptions = getAudioOptions(playbackLanguages?.audio_options);
  const subtitleOptions = getSubtitleOptions();
  const isHighDef = playbackLanguages?.media_features?.includes('HD');
  const is4K = playbackLanguages?.media_features?.includes('4K');
  const isFiveOneAudio = playbackLanguages?.media_features?.includes('5.1');
  const isDolbyAtmosAudio =
    playbackLanguages?.media_features?.includes('atmos');
  const duration = getDuration(playbackLanguages, film);

  const contentRating = film?.content_rating;
  const isValidContentRatingLabel =
    contentRating?.icon_url ||
    checkIsValidContentRatingLabel(contentRating?.label);
  const { content_warnings: contentWarnings = [] } = film;

  return (
    <FilmMeta data-testid="film-meta-container">
      {showGenres && (
        <FilmMetaItem>
          <Opacity>{genres}</Opacity>
        </FilmMetaItem>
      )}
      {isWatchable && isHighDef && (
        <FilmMetaItem data-testid="hd-icon">
          <Opacity>
            <FilmMetaHDIcon width="25px" height="16px" color={iconColor} />
          </Opacity>
        </FilmMetaItem>
      )}
      {isWatchable && is4K && (
        <FilmMetaItem data-testid="4k-icon">
          <Opacity>
            <FilmMeta4KIcon width="25px" height="16px" color={iconColor} />
          </Opacity>
        </FilmMetaItem>
      )}
      {isWatchable && isFiveOneAudio && !isDolbyAtmosAudio && (
        <FilmMetaItem data-testid="51-icon">
          <Opacity>
            <FilmMetaFiveOneAudioIcon
              width="25px"
              height="16px"
              color={iconColor}
            />
          </Opacity>
        </FilmMetaItem>
      )}
      {isWatchable && isDolbyAtmosAudio && (
        <FilmMetaItem data-testid="dolby-atmos-icon">
          <Opacity>
            <FilmMetaDolbyAtmosIconContainer>
              <FilmMetaDolbyAtmosAudioIcon
                width="90px"
                height="16px"
                color={iconColor}
              />
            </FilmMetaDolbyAtmosIconContainer>
          </Opacity>
        </FilmMetaItem>
      )}
      {duration && (
        <FilmMetaItem>
          <FilmMetaIconContainer>
            <Opacity>
              <FilmMetaDurationIcon width="18px" color={iconColor} />
            </Opacity>
          </FilmMetaIconContainer>
          <Opacity>
            <time dateTime={`PT${duration}M`} itemProp="duration">
              {duration}
            </time>
          </Opacity>
        </FilmMetaItem>
      )}
      {audioOptions && (
        <FilmMetaItem>
          <FilmMetaIconContainer>
            <Opacity>
              <FilmMetaAudioOptionsIcon width="20px" color={iconColor} />
            </Opacity>
          </FilmMetaIconContainer>
          <Opacity>{audioOptions}</Opacity>
        </FilmMetaItem>
      )}
      {subtitleOptions && (
        <FilmMetaItem>
          <FilmMetaIconContainer>
            <Opacity>
              <FilmMetaSubtitleOptionsIcon width="16px" color={iconColor} />
            </Opacity>
          </FilmMetaIconContainer>
          {subtitleOptions}
        </FilmMetaItem>
      )}
      {isWatchable && showContentRating && isValidContentRatingLabel && (
        <FilmMetaItem>
          <ContentRating
            contentRating={film.content_rating}
            contentWarnings={contentWarnings}
            overflowElementId={overflowElementId}
            uniqId={`${uniqueId}-content-rating`}
          />
        </FilmMetaItem>
      )}
    </FilmMeta>
  );
};

const Opacity = styled.div`
  opacity: 0.5;
`;

const FilmMeta = styled.ul`
  font-family: ${props => props.theme.font.body};
  list-style: none;
  display: flex;
  flex-wrap: wrap;
`;

const FilmMetaItem = styled.li`
  list-style: none;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  & svg,
  & time {
    vertical-align: middle;
  }
  &:not(:last-child) {
    margin-right: 12px;
  }
`;

const FilmMetaIconContainer = styled.div`
  margin-right: 4px;
`;

const MoreSubtitlesShort = styled.div`
  cursor: pointer;
`;

const FilmMetaDolbyAtmosIconContainer = styled.div`
  & svg {
    margin-top: 2px;
  }
`;

export default FilmPlaybackLanguagesMeta;
