import useTranslation from 'next-translate/useTranslation';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Film } from '@app/api/resources/Film';

import useOnboardingTrigger from '@app/hooks/helpers/useOnboardingTrigger';
import usePlayActionForConsumable from '@app/hooks/helpers/usePlayActionForConsumable';

import MubiButton from '@app/components/buttons/MubiButton/MubiButton';
import MubiButtonLink from '@app/components/buttons/MubiButton/MubiButtonLink';
import { PlayButtonIcon } from '@app/components/icons/PlayerIcons';

type FilmTilePlayButtonProps = {
  film: Film;
  onPlayButtonClick: () => void;
};

const FilmTilePlayButton = ({
  film,
  onPlayButtonClick,
}: FilmTilePlayButtonProps) => {
  const { t } = useTranslation('common');

  const onboardingTrigger = useOnboardingTrigger();
  const action = usePlayActionForConsumable(film, onboardingTrigger);

  const buttonContent = (
    <>
      <PlayContainer>
        <PlayButtonIcon width="100%" />
      </PlayContainer>
      <WatchFilmButtonText>
        {t('common:common.buttons.watch')}
      </WatchFilmButtonText>
    </>
  );

  if (action && action.href) {
    return (
      <PlayButtonPosition
        onClick={onPlayButtonClick}
        data-testid="film-tile-watch-film-button"
      >
        <WatchFilmLink url={action.href} isSpaLink={false}>
          {buttonContent}
        </WatchFilmLink>
      </PlayButtonPosition>
    );
  }

  if (action && action.onClick) {
    return (
      <PlayButtonPosition onClick={onPlayButtonClick}>
        <WatchFilmButton
          clickAction={action.onClick}
          actionType="primary"
          data-testid="film-tile-watch-film-button"
        >
          {buttonContent}
        </WatchFilmButton>
      </PlayButtonPosition>
    );
  }

  return null;
};

export default FilmTilePlayButton;

const baseButton = css`
  padding: 9px 17px;
  font-size: 14px;
  line-height: 14px;
  border-radius: 38px;
  height: 38px;
`;

const PlayButtonPosition = styled.div``;
const WatchFilmLink = styled(MubiButtonLink)`
  ${baseButton}
`;

const WatchFilmButton = styled(MubiButton)`
  ${baseButton}
`;

const PlayContainer = styled.div`
  width: 13px;
  height: 13px;
  margin-right: 8px;
`;

const WatchFilmButtonText = styled.div`
  font-weight: bold;
  text-transform: uppercase;
`;
