import { Film } from '@app/api/resources/Film';

import { FilmTrackingDetails } from '@app/services/snowplow';

import WideFilmTile from '@app/components/film-tile/wide-film-tile/WideFilmTile';
import TrackFilmImpression from '@app/components/today/TrackFilmImpression';

type WideFilmTileProps = {
  film: Film;
  onTileClick: () => void;
  onPlayButtonClick: () => void;
  snowplowElement?: string;
  showFilmActionGroupButtons?: boolean;
  showWatchListButton?: boolean;
  filmTrackingDetails?: FilmTrackingDetails;
  preloadFilmStill?: boolean;
};

const WideFilmTileWithImpressionTracking = ({
  film,
  onTileClick,
  onPlayButtonClick,
  snowplowElement = 'film_tile',
  showFilmActionGroupButtons = false,
  showWatchListButton = false,
  filmTrackingDetails,
  preloadFilmStill = false,
}: WideFilmTileProps) => {
  return (
    <TrackFilmImpression
      filmId={film.id}
      sectionNumber={filmTrackingDetails?.sectionNumber}
      indexInSection={filmTrackingDetails?.indexInSection}
      moduleType={filmTrackingDetails?.moduleType}
      horizontalPosition={filmTrackingDetails?.horizontalPosition}
      isWatchable={filmTrackingDetails?.isWatchable}
    >
      <WideFilmTile
        film={film}
        onTileClick={onTileClick}
        onPlayButtonClick={onPlayButtonClick}
        snowplowElement={snowplowElement}
        showFilmActionGroupButtons={showFilmActionGroupButtons}
        showWatchListButton={showWatchListButton}
        preloadFilmStill={preloadFilmStill}
      />
    </TrackFilmImpression>
  );
};

export default WideFilmTileWithImpressionTracking;
