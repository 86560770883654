import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { offersConsumableOfType } from '@app/api/resources/Consumables';
import {
  Film,
  getCountryYearStringForFilm,
  getStillFocalPoint,
} from '@app/api/resources/Film';

import { Breakpoints } from '@app/themes/mubi-theme';

import useGetFilmStillUrl from '@app/hooks/helpers/useGetFilmStillUrl';
import usePopulatePlaybackLanguagesForFilmId from '@app/hooks/populate-store/usePopulatePlaybackLanguagesForFilmId';

import FilmTileActions from '@app/components/film-tile/shared-film-tile-parts/FilmTileActions';
import FilmTileMeta from '@app/components/film-tile/shared-film-tile-parts/FilmTileMeta';
import FilmTilePlayButton from '@app/components/film-tile/shared-film-tile-parts/FilmTilePlayButton';
import FilmDirectors from '@app/components/FilmDirectors';
import Link from '@app/components/Link';
import ResponsivePicture from '@app/components/responsive-images/ResponsivePicture';

type WideFilmTileProps = {
  film: Film;
  onTileClick: () => void;
  onPlayButtonClick: () => void;
  snowplowElement?: string;
  showFilmActionGroupButtons?: boolean;
  showWatchListButton?: boolean;
  preloadFilmStill?: boolean;
};

const WideFilmTile = ({
  film,
  onTileClick,
  onPlayButtonClick,
  snowplowElement = 'film_tile',
  showFilmActionGroupButtons = false,
  showWatchListButton = false,
  preloadFilmStill = false,
}: WideFilmTileProps) => {
  const { t } = useTranslation('showing');
  const isFreeFilm = offersConsumableOfType(film.consumable, 'free');
  const [playbackLanguages] = usePopulatePlaybackLanguagesForFilmId(film?.id);
  const filmStillImageUrl = useGetFilmStillUrl(film);

  const extraButtonProps: {
    text?: string;
  } = {};
  if (isFreeFilm) {
    extraButtonProps.text = t('common:common.buttons.free');
  }

  const countryYearString = getCountryYearStringForFilm(film);

  const editorialContent = film.default_editorial || film.short_synopsis;

  return (
    <Container editorialContentLength={editorialContent?.length || 0}>
      <FilmStillSection bgColor={film.average_colour_hex}>
        <PictureSection>
          <StyledResponsivePicture
            src={filmStillImageUrl}
            alt={film.title}
            sizeInPixels={200}
            responsiveSizesInPixels={{
              mobile: 350,
              tablet: 800,
              desktopAndUp: 900,
            }}
            objectFit="cover"
            backgroundPosition={getStillFocalPoint(film)}
            shouldPreload={preloadFilmStill}
          />
          <GradientOverlay />

          <FilmStillLink onClick={onTileClick} href={`/films/${film.slug}`} />
        </PictureSection>

        <FilmStillSectionInner>
          <FilmTitle>{film?.title_upcase}</FilmTitle>
          <FilmAbout>
            {film.directors && (
              <StyledFilmDirectors
                directors={film.directors}
                listAsLinks
                isUppercase
              />
            )}
            {countryYearString && (
              <FilmCountryYear>{countryYearString}</FilmCountryYear>
            )}
          </FilmAbout>
        </FilmStillSectionInner>
      </FilmStillSection>

      <InfoSection>
        <InfoInner>
          <FilmSynopsis
            dangerouslySetInnerHTML={{
              __html: film.default_editorial_html || film.short_synopsis_html,
            }}
          />
          <FilmTileButtons>
            <FilmTilePlayButton
              film={film}
              onPlayButtonClick={onPlayButtonClick}
            />
            {showFilmActionGroupButtons && (
              <FilmTileActionsContainer>
                <FilmTileActions
                  film={film}
                  isVisible
                  showWatchListButton={showWatchListButton}
                  showRatingButton
                  snowplowElement={snowplowElement}
                  theme="blue"
                />
              </FilmTileActionsContainer>
            )}
          </FilmTileButtons>
        </InfoInner>
        <FilmInfoBottom>
          <FilmTileMeta film={film} playbackLanguages={playbackLanguages} />
        </FilmInfoBottom>
      </InfoSection>
    </Container>
  );
};

const getTileHeight = (
  editorialContentLength: number,
  breakpoint: Breakpoints,
) => {
  if (breakpoint === 'wide') {
    return 'height: 422px;';
  }

  if (breakpoint === 'desktop') {
    if (editorialContentLength < 270) {
      return 'height: 280px;';
    } else {
      return 'height: 320px;';
    }
  }

  if (breakpoint === 'tablet') {
    if (editorialContentLength < 220) {
      return 'height: 238px;';
    } else if (editorialContentLength < 270) {
      return 'height: 250px;';
    } else {
      return 'height: 270px;';
    }
  }

  return '';
};

const Container = styled.article<{
  editorialContentLength: number;
}>`
  position: relative;
  background-color: ${props => props.theme.color.white};
  width: 100%;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    display: flex;
    ${props => getTileHeight(props.editorialContentLength, 'tablet')}
  }
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    ${props => getTileHeight(props.editorialContentLength, 'desktop')}
  }
  @media (min-width: ${props => props.theme.mqNew.wide}) {
    ${props => getTileHeight(props.editorialContentLength, 'wide')}
  }
`;

const PictureSection = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledResponsivePicture = styled(ResponsivePicture)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  img {
    width: 100%;
    height: 100%;
  }
`;

const GradientOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  &:after {
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.375) 0%,
      transparent 50%,
      transparent 75%,
      rgba(0, 0, 0, 0.25) 100%
    );

    content: ' ';
    display: block;

    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
  }
`;

type FilmStillSectionProps = {
  bgColor: string;
};
const FilmStillSection = styled.div<FilmStillSectionProps>`
  position: relative;
  color: ${props => props.theme.color.white};
  background-color: ${props => `#${props.bgColor}`};
  height: 226px;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    height: auto;
    width: 50%;
  }
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    width: auto;
    flex: 1;
  }
`;

const FilmStillLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const FilmStillSectionInner = styled.div`
  height: 100%;
  width: 100%;
  padding: 19px 23px;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
`;

const InfoSection = styled.div`
  position: relative;
  color: ${props => props.theme.color.darkText};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 0 15px 20px;
  min-height: 226px;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    min-height: 238px;
    width: 50%;
  }
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    min-height: 280px;
    width: 376px;
    padding: 20px 20px 15px 24px;
  }
  @media (min-width: ${props => props.theme.mqNew.wide}) {
    padding: 31px 20px 15px 24px;
    min-height: 422px;
  }
  @media (min-width: ${props => props.theme.mqNew.wideLarge}) {
    width: 752px;
  }
`;

const InfoInner = styled.div`
  display: block;

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    margin-top: 0;
    position: relative;
  }

  @media (min-width: ${props => props.theme.mqNew.wide}) {
  }
`;

const FilmTitle = styled.h2`
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
  font-weight: bold;
  font-size: 22px;
  line-height: 24px;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    font-size: 26px;
    line-height: 28px;
  }
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    font-size: 34px;
    line-height: 35px;
  }
`;

const FilmAbout = styled.h3`
  color: ${props => props.theme.color.white};
  line-height: 20px;
  margin-top: 0px;

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    margin-top: 3px;
  }
`;

const StyledFilmDirectors = styled(FilmDirectors)`
  font-weight: bold;
`;

const FilmCountryYear = styled.span`
  text-transform: uppercase;
`;

const FilmSynopsis = styled.div`
  padding-right: 20px;
  margin-bottom: 18px;
  margin-top: 20px;
  font-size: 13px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;

  a {
    color: ${props => props.theme.color.darkText};
    text-decoration: underline;
    position: relative;
    z-index: 5;

    &:hover {
      color: ${props => props.theme.color.darkText};
    }
  }

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    margin-top: 22px;
    margin-bottom: 13px;

    -webkit-line-clamp: 9;
  }
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    font-size: 14px;
    margin-top: 2px;
    margin-bottom: 16px;
    -webkit-line-clamp: 10;
  }
  @media (min-width: ${props => props.theme.mqNew.wide}) {
    line-height: 22px;
    margin-bottom: 20px;
    -webkit-line-clamp: 14;
  }
  @media (min-width: ${props => props.theme.mqNew.wideLarge}) {
    font-size: 18px;
    line-height: 26px;
  }
`;

const FilmTileButtons = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

const FilmTileActionsContainer = styled.div`
  padding-left: 13px;
`;

const FilmInfoBottom = styled.div`
  display: flex;
  height: initial;
  width: 100%;
  & ul {
    flex-wrap: wrap !important;
  }
`;

export default WideFilmTile;
